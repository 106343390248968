import React from 'react'
import starIcon from '../../assets/images/star-icon.png'

const VitaliaDeployments = () => {
    const sites = [
        {SiteName : "Indus Hospial & Health Network, Pakistan "  , Desc: "",  DeployDate: {Month: "January" , year: "2019" }},
        {SiteName : "Saudi German Hospital, Riyadh, KSA"  , Desc: "",  DeployDate: {Month: "April" , year: "2020" }},
        {SiteName : "Saudi German Hospital Madinah, KSA"  , Desc: "",  DeployDate: {Month: "october" , year: "2020" }},
        {SiteName : "Saudi German Hospital Hail, KSA"  , Desc: "",  DeployDate: {Month: "january" , year: "2021" }}
    ]
    return (
        <div className="history-area ptb-100" id = "vitalia-deploy">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Our History
                    </span>
                    <h2>Vitalia Deployments</h2>
                </div>

                <ol className="timeline history-timeline history-timeline-style-two">
                {
                        sites.sort((a,b) => a.DeployDate.year - b.DeployDate.year).map(site => ( 
                            <li className="timeline-block">
                            <div className="timeline-date">
                                <span>{site.DeployDate.year}</span>
                                {site.DeployDate.Month}
                            </div>
    
                            <div className="timeline-icon">
                                <span className="dot-badge"></span>
                            </div>
    
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            {/* <h3>Founded</h3> */}
                                            <p>{site.SiteName}</p>
                                        </div>
                                    </div>
    
                                    <div className="col-lg-5 col-md-12">
                                        {/* <div className="image">
                                            <img src={history1} alt="banner" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </li>

                        ))}
                </ol>
            </div>
        </div>
    )
}

export default VitaliaDeployments